/* Container for business links */
.business-links {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns in desktop view */
  gap: 40px; /* Reduced gap between items for cleaner spacing */
  padding: 20px 20px; /* Added padding around the whole section */
  justify-items: center;
  text-align: center; /* Ensures title and content are centered */
  background-color: #f3ede6;
}

/* Title styling */
.business-links h1 {
  grid-column: 1 / -1; /* Makes the title span all columns */
  text-align: center;
  font-size: 50px;
  margin-bottom: 20px;
  color: black;
  font-family: "Rasputin Light";
  font-weight: 200;
}

/* Individual business links */
.business-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 10px; /* Adds spacing around the logos */
}

/* Business logo styling */
.business-logo {
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds subtle shadow for depth */
}

/* Hover effect */
.business-link:hover .business-logo {
  transform: scale(1.05);
}
  
  @media (max-width: 768px) {
    .business-links {
      padding: 10px;
      gap: 20px;
      grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile view */
    }
    .business-logo {
      max-width: 114px; /* Prevents it from becoming too large */
      max-height: 76px; /* Prevents it from becoming too large */
    }
    .business-links h1 {
      font-size: 30px;
    }
  }
  
  .business-link {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
  
  .business-logo {
    width: 180px; /* Fixed width */
    height: 120px; /* 3:2 aspect ratio */
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .business-link:hover .business-logo {
    transform: scale(1.05);
  }
  

  