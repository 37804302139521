/* Container to center everything */
.phoneLink {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  align-items: center;
  text-align: center; /* Ensures text is centered */
  height: 200px; /* Adjust height for better spacing */
}

/* Centers title */
.phoneLink h1 {
  text-align: center;
  margin-bottom: 50px;
  margin-bottom: 30px;
  font-size: 30px;

}

/* Centers phone number */
.phone-link-number {
  font-size: 1.2rem;
  color: black;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;

}

/* Underline on hover */
.phone-link-number:hover {
  text-decoration: underline;
}
