/* GeneralSearch.css */

.general-search-form {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px 5px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

.initial-message {
  text-align: left; /* Aligns text to the left */
  display: block; /* Ensures it behaves as a block element */
  margin-top: 30px;
}

.initial-message p {
  font-size: 12px;
}

.general-search-form p {
  text-align: center;
}

/* Title */
.general-search-form h2 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0px;
}

/* Flex row for Address, Category, and Date */
.form-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

/* Group first three inputs in the same row */
/* Make Address & Category in one row */
.first-three-inputs {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
}

/* NEW: Date, Start Time & End Time in a row */
.date-time-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}

/* Reduce width for time pickers */
.date-time-row .form-group {
  flex: 1;
  min-width: 150px;
}

/* Ensure dropdowns fit well */
.date-time-row select {
  padding: 8px;
  font-size: 14px;
}

/* Responsive: Stack Date & Time vertically on mobile */
@media (max-width: 768px) {
  .first-three-inputs,
  .date-time-row {
      flex-direction: column;
      gap: 10px;
  }
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

/* Style labels */
.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
}

/* Input and Select Styles */
.form-group input,
.form-group select,
.form-group textarea {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
}

textarea {
  resize: vertical;
  margin-bottom: 40px;

}

/* Submit Button Styling */
.search-all-button {
  background-color: var(--marketplaceColor);
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 20%;
  margin: 0 auto;
}

.search-all-button:hover {
  background-color: var(--marketplaceColorDark);
}

/* Prevent initial message from affecting component size */
.form-group p {
  margin: 0;
  padding: 0;
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
  visibility: visible;
  height: 1em;
  line-height: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row,
  .first-three-inputs {
      flex-direction: column;
      gap: 10px;
  }

  .search-all-button {
      width: 100%;
  }
}

/* Snackbar Custom Styling */
.MuiSnackbar-root {
  z-index: 1500;
}

.MuiAlert-root {
  font-size: 16px;
}

/* GeneralSearchPopup.css */

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns items at the top vertically */
  align-items: flex-start; /* Aligns items to the left horizontally */
}

/* Default desktop view */
.backgroundContainer {
  position: relative;
  height: 50vh;
  width: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

.openButton {
  padding: 12px 24px; /* Increased padding */
  font-size: 1.2rem; /* Slightly larger text */
  font-weight: 600; /* A bit bolder */
  border-radius: 8px; /* Slightly rounder corners */
  background-color: white;
  color: black;
  cursor: pointer;
  margin-top: 40px;
}

.openButton:hover {
  background-color: #f0f0f0; /* Light gray */
}

.closeButton {
  position: absolute;
  right: 8px;
  top: 8px;
  color: #333;
}

.closeButton:hover {
  color: #000;
}

/* Phone view adjustments */
@media (max-width: 768px) {
  .backgroundContainer {
      height: 30vh;
      width: 100%;
      justify-content: flex-start;
  }

  .backgroundImage {
      height: auto;
      max-height: 70vh;
      width: 100%;
      object-fit: cover;
  }

  .openButton {
      margin-top: 30px;
      font-size: 1.15rem;

  }
}
