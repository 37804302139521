.testimonial-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    background-color: #f3ede6;
}
.testimonial-title {
    font-family: "Rasputin Light";
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3ede6;
    color: black;
    margin: 0; /* Remove default margin */
    padding: 40px 0; /* Add controlled padding */
    font-size: 50px;
    font-weight: 200;

}

.testimonial-carousel {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    width: 80%;
    gap: 20px;
    margin-bottom: 50px;
    margin-bottom: 50px;
}

.testimonial-item {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center elements horizontally */
    text-align: center; /* Center text content */
    padding: 30px 20px 30px;
    width: 30%;
    box-sizing: border-box;
    height: 465px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    transition: transform 0.3s ease;
    position: relative;
    margin-top: 50px;
}
.testimonial-item:hover {
    transform: translateY(-5px);
}

.testimonial-header {
    justify-content: center; /* Center header contents */
    text-align: center;
    display: flex;
    align-items: center;
}

.testimonial-picture {
    border-radius: 50%;
    width: 130px;
    height: 130px;
    object-fit: cover;
    margin-bottom: 20px;
}

.testimonial-stars {
    color: #FFD700;
    font-size: 1.2em;
    margin-top: 10px;
}

.testimonial-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 80px;
}

.testimonial-review {
    font-style: italic;
    font-size: 1em;
    color: #555555;
    line-height: 1.5;
}

.testimonial-footer {
    margin-top: auto;
    text-align: left;
    width: 100%;
}

.testimonial-name {
    font-weight: bold;
    margin-top: 20px;
    font-size: 1.1em;
    color: #333333;
    text-align: center; /* Center text for these items */
    margin: 0 auto; /* Center align in their containers */
}

.testimonial-location {
    font-size: 0.9em;
    color: #777777;
    margin-top: 5px;
    text-align: center; /* Center text for these items */
    margin: 0 auto; /* Center align in their containers */
}

.arrow {
    background-color: transparent;
    border: none;
    font-size: 2.5rem;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    transition: color 0.3s ease;
    margin-right: 10px;
    margin-left: 10px;
}

.arrow:hover {
    color: #333333;
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

/* Mobile layout: show 1 testimonial at a time */
@media (max-width: 768px) {
    .testimonial-item {
        max-width: 100%;
        text-align: center;
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        box-sizing: border-box;
        height: 450px; /* Set a fixed height */
        margin-top: 30px;
    }

    .testimonial-header {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }

    .testimonial-review {
        font-style: italic;
        font-size: 1.2em;
        color: #555555;
        line-height: 1.5;
        margin-top: 10px;
        text-align: center;
        max-height: 300px; /* Limit review text height */
        overflow-y: hidden;
        
    }

    .testimonial-footer {
        margin-top: auto; /* Push footer to the bottom */
        text-align: center;
        width: 100%; /* Ensure full width */
        padding-top: 10px; /* Add space above footer for separation */
    }

    .testimonial-name,
    .testimonial-location {
        margin: 0 auto;
        text-align: center;
    }

    .arrow {
        font-size: 2rem;
        top: 58%;
    }

    .testimonial-title {
        font-size: 30px;
    }
}

